@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 0 0% 15%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96%;
    --secondary-foreground: 0 0% 15%;

    --muted: 0 0% 96%;
    --muted-foreground: 0 0% 45%;

    --accent: 0 0% 96%;
    --accent-foreground: 0 0% 15%;

    --link: 208 100% 47%;

    --red: 0 89% 74%;

    --green: 147 71% 73%;

    --orange: 40 100% 73%;

    --blue: 208 100% 84%;

    --pink: 327 94% 81%;

    --purple: 266 100% 85%;

    --yellow: 50 98% 75%;

    --cyan: 187 85% 75%;

    --background: 0 0% 100%;
    --foreground: 0 0% 15%;

    --border: 0 0% 91%;

    --radius: 1rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
